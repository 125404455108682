import '../styles/globals.css'
import '../styles/nprogress.css'
import '@reach/skip-nav/styles.css'
import 'react-toastify/dist/ReactToastify.min.css'
import '@reach/menu-button/styles.css'
import '@reach/dialog/styles.css'
import '@reach/accordion/styles.css'
import 'swiper/css'
import 'swiper/css/pagination'
import type { AppProps } from 'next/app'
import { Analytics } from '@vercel/analytics/react'
import NProgress from 'nprogress'
import { MediaContextProvider } from '~/lib/Media'
import { KlevuConfig } from '@klevu/core'
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { DefaultSeo } from 'next-seo'
import Script from 'next/script'
import TagManager from 'react-gtm-module'
import { appWithTranslation, useTranslation } from 'next-i18next'
import { config } from 'lib/queryClientInit'
import { NextPage } from 'next'
import { UIProvider } from '~/lib/Context/UIProvider'
import { useRouter } from 'next/router'
import { getUsercentricsId } from '../lib/usercentricsCountryId'
import { DEFAULT_LOCALE } from '~/config/constants'
import Head from 'next/head'
import { log } from '~/lib/log'
import { DM_Sans } from '@next/font/google'
import localFont from 'next/font/local'

export type NextPageWithLayout<Props = {}> = NextPage<Props> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const tagManagerArgs = { gtmId: `${process.env.NEXT_PUBLIC_GTM_KEY}` }

KlevuConfig.init({
  url: process.env.NEXT_PUBLIC_KLEVU_URL || '',
  apiKey: process.env.NEXT_PUBLIC_KLEVU_KEY || '',
})

const dmSans = DM_Sans({
  weight: ['400', '500', '700'],
  style: ['normal', 'italic'],
  variable: '--font-dm-sans',
  subsets: ['latin'],
  display: 'swap',
})
const dmSerif = localFont({
  src: [
    {
      path: '../public/fonts/benuta-serif-medium.woff2',
    },
    {
      path: '../public/fonts/benuta-serif-medium.ttf',
    },
    {
      path: '../public/fonts/benuta-serif-medium.otf',
    },
  ],
  variable: '--font-dm-serif',
  display: 'swap',
})
function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const { t } = useTranslation()
  const [queryClient] = useState(() => new QueryClient(config))
  const router = useRouter()
  useEffect(() => {
    ;(document as any).current_url = document?.URL
    ;(document as any).previous_url = document?.referrer

    TagManager.initialize(tagManagerArgs)
  }, [])

  useEffect(() => {
    const handleStart = (url: string) => {
      log(`Loading: ${url}`)
      ;(document as any).previous_url = (document as any)?.current_url
      ;(
        document as any
      ).current_url = `${window.location.protocol}//${window?.location?.host}${url}`

      NProgress.start()
    }
    const handleStop = () => {
      NProgress.done()

      if (process.env.NEXT_PUBLIC_DOTDIGITAL_IDENTIFICATION_NUMBER) (window as any)?.dmPt('track')
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router])

  // Use the layout defined at the page level, if available
  // https://nextjs.org/docs/basic-features/layouts#with-typescript
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${dmSans.style.fontFamily};
        }
        .font-dm-serif {
          font-family: ${dmSerif.style.fontFamily} !important;
        }
      `}</style>
      <div className={`${dmSans.variable} ${dmSerif.variable} font-sans`}>
        <MediaContextProvider disableDynamicMediaQueries>
          <QueryClientProvider client={queryClient}>
            <Hydrate
              state={
                Object.keys(pageProps).includes('dehydratedState')
                  ? (pageProps as any).dehydratedState
                  : {}
              }
            >
              <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
              </Head>
              <DefaultSeo
                titleTemplate="%s"
                title={t('meta.titleFallback')}
                // dangerouslySetAllPagesToNoFollow
                // dangerouslySetAllPagesToNoIndex
              />
              {process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ? null : (
                <Script
                  id="usercentrics-cmp"
                  strategy="afterInteractive"
                  data-settings-id={getUsercentricsId(router.locale || DEFAULT_LOCALE)}
                  src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
                  data-tcf-enabled
                />
              )}

              <UIProvider>{getLayout(<Component {...pageProps} />)}</UIProvider>
              <Analytics />
            </Hydrate>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </MediaContextProvider>
      </div>
    </>
  )
}

export default appWithTranslation(MyApp)
